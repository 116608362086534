.p-top {

    .p-project {
        justify-content: space-between;

        .p-project__cover {
            overflow: initial;

            img {
                width: 960px;
                max-height: 540px;
                object-fit: cover;
            }
        }

        .p-project__detail {
            width: 32%;
        }

        @media screen and (max-width: 600px) {

            .p-project__detail {
                width: 100%;
            }

            .p-project__cover {
                img {
                    width: 100%;
                }
            }
        }
    }
}

.p-detail {
    .p-video__wrapper {
        position: inherit;

        .p-masthead {
            z-index: 0;

            .p-masthead__video__wrapper {
                z-index: 0;
            }
        }
    }

    @media screen and (max-width: 961px) {
        .p-video__wrapper {
            margin-top: 0;
        }
    }

    .p-freearea {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            margin-top: initial;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 1;
    z-index: 99999;

    .popup {
        margin: 25vh auto;
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        width: 20%;
        position: relative;

        .close {
            position: absolute;
            top: 4px;
            right: 8px;
            font-size: 30px;
            font-weight: bold;
            text-decoration: none;
            color: #333;
            transition: all 200ms;
        }

        .close:hover {
            color: #d80629;
        }

        .popup-header {
            padding: 10px;

        }
    }

    @media screen and (max-width: 600px) {
        .popup {
            width: 70%;
        }
    }
}


@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.lds-spinner {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border-width: 3px;


    position: relative;
    margin: auto;
    animation: 0.66s linear 0s infinite normal none running spinner;
    border-style: solid;
    border-color: rgb(230, 230, 230) rgb(230, 230, 230) rgb(230, 230, 230) rgb(0, 0, 0);
    transform: translateZ(0px);
    user-select: none;
}

.tbl {
    display: table;
}

.tr {
    display: table-row;
}

.td {
    display: table-cell;
    vertical-align: middle;
    padding: 5px;
}

.p-404 {
    padding: 30px;
    height: 49vh;
    text-align: center;

    h3 {
        font-size: 30px;
    }
}

.c-button {
    &:disabled {
        background: #a3a3a3;
        cursor: not-allowed;
    }
}

.p-top .p-project__fund-meter--0 {
    padding-right: 10px;
    background: #d8dfe4;
    display: flex;
    align-items: center;
    justify-content: right;
    width: 78%;
    height: 20px;
    border-radius: 100px;
    color: #000;
    font-weight: 700;
    font-size: 12px;
}

@media only screen and (max-width: 1500px) {
    .p-top {
        .p-project {
            .p-project__detail {
                min-width: 350px;
            }

            .p-project__cover img {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1281px) {
    .p-top {
        .p-project {
            flex-flow: inherit;

            .p-project__cover {
                max-width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .p-top {
        .p-project {
            flex-flow: column;

            .p-project__detail {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .p-top {
        .p-project {
            .p-project__detail {
                min-width: auto;
                width: 100%;
            }
        }
    }
}

.c-button {
    &.disabled {
        background: #a3a3a3;
        cursor: not-allowed;
    }
}

.p-video__detail {
    .p-video__fund-meter--0 {
        padding-right: 10px;
        background: #d8dfe4;
        display: flex;
        align-items: center;
        justify-content: right;
        width: 78%;
        height: 20px;
        border-radius: 100px;
        color: #000;
        font-weight: 700;
        font-size: 12px;
    }
}

.main-top {
    min-height: 60vh;
}

.pull-right {
    float: right;
}

.line-button {
    padding: 1.2vw 0;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: 700;
    border-radius: 6px;
    text-align: center;
    flex: 1 1;
    display: flex;
    font-size: clamp(16px, 1.6vw, 22px);
    transition: all 0.4s;
    background: #06C755;
    color: #FFFFFF;
    
    &:hover {
        background: #06C755;
    }
    &:focus {
        background: #06C755;
    }
    img {
        width: auto;
        height: 40px;
    }
}

.auth-line {

    .content-404 {
        margin: 0 auto;
        padding: 100px;
        width: fit-content;
        background: #06C755;
        color: #FFFFFF;
    }
}